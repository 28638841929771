/**
 * Aktiv Grotesk Light 
 *
font-family: aktiv-grotesk, sans-serif;
font-weight: 300;
font-style: normal;

font-family: aktiv-grotesk, sans-serif;
font-weight: 300;
font-style: italic;
 */

/**
 * Aktiv Grotesk Regaular 
 * 
font-family: aktiv-grotesk, sans-serif;
font-weight: 400;
font-style: normal;

font-family: aktiv-grotesk, sans-serif;
font-weight: 400;
font-style: italic;
 */

/**
 * Aktvi Grotesk Medium 
 *
font-family: aktiv-grotesk, sans-serif;
font-weight: 500;
font-style: normal;

font-family: aktiv-grotesk, sans-serif;
font-weight: 500;
font-style: italic;
 */

$font-stack: aktiv-grotesk, PingFangTC-Regular, LiHei Pro, 'Hiragino Sans GB', 'Microsoft JhengHei', STXihei, sans-serif;

html {
	font-size: 62.5%;
	/* avoid the font look to bold on OSX */
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
	/*-webkit-font-smoothing: antialiased;*/
}

body {
	font-family: $font-stack;
  font-weight: 200;
  font-size: 21px;
  line-height: 1.3;
  letter-spacing: 0;
	
  @media only screen and (max-width: $bs-md) {
    font-size: 16px;
  }
}

p {
	line-height: 1.5;
}

strong {
	font-weight: 700;
}

h1 {
    font-weight: 500;
    font-size: 33px;
    line-height: 1;

    @media only screen and (max-width: $bs-md) {
      font-size: 20px;
    }
}

h2 {
	font-weight: 500;
	font-size: 33px;
  line-height: 1.3;

  @media only screen and (max-width: $bs-md) {
    font-size: 30px;
  }
}

h2.section__title {
	font-weight: 500;
	font-size: 33px;
  line-height: 1.2;

  @media only screen and (max-width: $bs-md) {
    font-size: 23px;
  }

	@at-root .home.page h2.section__title {
		font-size: 40px;
		
		@media only screen and (max-width: $bs-xs) {
			font-size: 23px;
		}

		a {
			font-weight: 400;
			font-size: 19px;
			line-height: 1;
		}
	}
}

h3.section__title {
	font-weight: 500;
	font-size: 30px;
	line-height: 1;

  @media only screen and (max-width: $bs-xs) {
    font-size: 20px;
  }
}

h3.section__title {
  font-family: $font-stack;
  font-weight: 500;
  font-size: 21px;
  line-height: 1.3;
  letter-spacing: 0;
	
  @media only screen and (max-width: $bs-md) {
    font-size: 16px;
  }
}

select,
.selectboxit-container .selectboxit-text {
	font-weight: 200;
	font-size: 21px;
}
.selectboxit-container .selectboxit-options a {
	font-weight: 200;
	font-size: 18px;
	line-height: 1.4;
}

.fancy-field_file_remove-file-btn {
	font-size: 17px;
}

.text-link {
	font-weight: 400;
	font-size: 19px;
  
  @media only screen and (max-width: $bs-xs) {
    font-size: 15px;
  }
}

.announcement-banner a {
	font-weight: 500;
}

header.template {
	.nav-item {
		a {
			font-weight: 400;
			font-size: 21px;
			line-height: 1;
		}
		
		.dropdown-menu a {
			font-weight: 200;
			
			@media only screen and (max-width: $bs-md) {
				font-size: 14px;
			}
		}
	}
}

footer.template {
	address * {
		font-size: 19px;
    
		@media only screen and (max-width: $bs-md) {
			font-size: 16px;
		}
	}
	
	nav {
		a {
			font-weight: 200;
			font-size: 19px;
			line-height: 1;
      
      @media only screen and (max-width: $bs-md) {
        font-size: 16px;
      }
		}
	}
	
	.footer__nav {
		a {
			font-weight: 500;
			font-size: 19px;
			line-height: 1;
      
      @media only screen and (max-width: $bs-md) {
        font-size: 16px;
      }
		}
	}
	
	.nav-item_special {
		font-weight: 500;
		font-size: 16px;
		line-height: 1;
	}

  .footer__nav__social-media a {
    font-size: 20px;
  }
}

.home.page {
	#banner .banner-item_text {
		& * {
			font-weight: 600;
			font-size: px2vw(79px);
			line-height: 1.21;

			@media only screen and (min-width: $container) {
				font-size: 79px;
			}

			@media only screen and (max-width: $bs-md) {
				font-size: px2vw(75px);
			}

			@media only screen and (max-width: $bs-xs) {
				font-size: px2vw(70px);
			}
		}
	}
}

.home.page #our-services aside,
.home.page #our-services main {
	font-size: 19px;
	line-height: 1.3;

	h2 {
		font-weight: 500;
		font-size: 19px;
		line-height: 1;

    @media only screen and (max-width: $bs-xs) {
      font-size: 15px;
    }
	}
	
	h3 {
		font-weight: 400;
		font-size: 19px;
		line-height: 1;
    
    @media only screen and (max-width: $bs-xs) {
      font-size: 15px;
    }
	}
	
	.text-link,
	label {
		font-weight: 400;
		font-size: 16px;
		line-height: 1;
    
    @media only screen and (max-width: $bs-xs) {
      font-size: 14px;
    }
	}
}

.home.page #promotion {
	.testimonial-item_name {
		font-weight: 500;
		font-size: 21px;
		line-height: 1;
	}
}

.home.page .call-to-action {
	font-weight: 500;
}

.home.page .latest-project-item label {
	font-size: 16px;
	line-height: 1.3;
}

.blog-item h2 {
	font-size: 30px;

  @media only screen and (max-width: $bs-md) {
    font-size: 20px;
  }
	
	@at-root .home.page & {
		font-size: 40px;
	}
}

.project-listing-item {
	&_text {
		h2 {
			font-weight: 500;
			font-size: 33px;
			line-height: 1.1;
		}
		
		p {
			font-size: 18px;

      @media only screen and (max-width: $bs-md) {
        font-size: 16px;
      }
		}
	}
}

.project-listing.page .project-listing_text a.special-link {
	font-weight: 500;
}

.project.page {
	.project-detail {
		&_type,
		&_website-link,
		main *,
		.project-description * {
			font-size: 18px;
      
      @media only screen and (max-width: $bs-md) {
        font-size: 16px;
      }
		}
	}
}

.blog-story {
	&_back {
		font-weight: 400;
		font-size: 19px;

    @media only screen and (max-width: $bs-md) {
      font-size: 16px;
    }
	}
}

.section__txt_row {
	&._style-1 {
		h2.section__title {
			font-weight: 500;
			font-size: 21px;
			
			@media only screen and (max-width: $bs-xs) {
				font-size: 18px;
			}
		}
	}
}
