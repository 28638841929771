$container: 1200px;
$padding-left: 20px;
$padding-right: 20px;

$bs-lg: 1199px;
$bs-md: 991px;
$bs-sm: 767px;
$bs-xs: 575px;

@mixin transform($property) {
	-webkit-transform: $property;
	-ms-transform: $property;
	transform: $property;
}

@mixin transition($property...) {
	-webkit-transition: $property;
	        transition: $property;
}

@function px2vw($property) {
	@return #{$property/$container*100}vw;
}

/* Automatically clear elements for standards-compliant browsers */
.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
/* Trigger has-layout for IE compatibility */
.clearfix {
	zoom: 1;
}

._hidden,
[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

#screen_measurement {
	position: fixed; bottom: 0px; right: 0px; z-index: 999; background-color: rgb(255, 255, 255); font-size: 11px; font-weight: 700; line-height: 15px;

	display: none;
}
#screen_measurement > * {
	color: #000000;
}

#screen_measurement #display_classes > div {
	padding: 0 5px;
}

.error {
	color: #ff0000;
}

.message {
	color: #006e00;
}

img {
	max-width: 100%;
	height: auto;
}

.map_canvas img { max-width: none; } /* fix the google map issue */

.fixed_center,
.fixed_center_responsive {
	position: absolute;
	width: 100%;
	top: 50%;
	left: 50%;

	-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
}

h1,h2,h3,h4,h5 {
	margin: 0;
}

/*
 * project styling START
 */

body {
	/*overflow-y: scroll;*/ /* has to be scroll, not auto */
	-webkit-overflow-scrolling: touch;
	color: #000000;
}

/* body.nav_open {
	position: fixed;
	width: 100%;
} */

.container {
	max-width: $container;
}

.container-fluid {
	padding-left: $padding-left;
	padding-right: $padding-right;
}

.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
}
.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #f4436c;
	border-color: #f4436c transparent #f4436c transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

p {
	margin-bottom: 0;
}

h1, h2,
ul, ol,
table {
	margin-bottom: 17px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

a {
	text-decoration: none;
	color: #000000;
	@include transition(color 0.5s ease);
}

a:focus,
a:hover {
	color: #F4436C;
	text-decoration: none;
}

/* .form-group {
	position: relative;
	margin-bottom: 45px;
}

.fancy-field {
	width: 100%;
	display: block;
}

.fancy-field {
	.field_title {
		position: absolute;
		top: 10px;
		left: 0;
		color: #808080;
		-webkit-transition: top 0.5s ease;
		        transition: top 0.5s ease;
	}
}

.fancy-field input:not(:placeholder-shown) + .field_title,
.fancy-field input:focus + .field_title,
.fancy-field textarea:not(:placeholder-shown) + .field_title,
.fancy-field textarea:focus + .field_title {
	top: -25px;
}

.fancy-checkbox,
.fancy-radio {
	position: relative;
}

.fancy-checkbox input,
.fancy-radio input {
	position: absolute;
	top: 0;
	left: 0;
	width: 25px;
	height: 100%;
	z-index: 1;
	opacity: 0;
}

.fancy-checkbox input + label,
.fancy-radio input + label {
	position: relative;
	padding-left: 35px;
	margin-bottom: 0;
}

.fancy-checkbox input + label:before,
.fancy-checkbox input + label:after,
.fancy-radio input + label:before,
.fancy-radio input + label:after {
	content: "";
	position: absolute;
}

.fancy-radio input + label:before,
.fancy-radio input + label:after {
	border-radius: 50%;
}

.fancy-checkbox input + label:before,
.fancy-radio input + label:before {
	border: 1px solid #B0B0B0;
	width: 25px;
	height: 25px;
	top: 0;
	left: 0;
}

.fancy-checkbox input:checked + label:after,
.fancy-radio input:checked + label:after {
	background-color: #333333;
	width: 15px;
	height: 15px;
	top: 5px;
	left: 5px;
} */

.fancy-field_file {
	position: relative;
	border-bottom: 1px solid #C9C9C9;
	padding-bottom: 4px;
	
	&, & * {
		cursor: pointer;
	}
	
	input {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
	
	span {
		display: block;
		width: 100%;
		height: 35px;
	}
	
	&_remove-file-btn {
		cursor: pointer;
		color: #ff0000;
	}
}

.selectboxit-container {
	margin-right: 30px;
	position: relative;
	width: 100%;
	min-width: 225px;
	
	&:last-of-type {
		margin-right: 0;
	}
	
	& * {
		color: #000000;
		white-space: normal;
		line-height: 0;
	}

  .selectboxit-option-icon-container {
    display: none;
  }

	.selectboxit-text {
		height: 33px;
		line-height: 30px;
	}
	
	.selectboxit {
		border-bottom: 1px solid #919191;
		width: 100%;
	}
	
	&:hover .selectboxit {
		border-color: #f4436c;
	}

	.selectboxit-arrow-container {
		position: absolute;
		right: 0;
		top: 5px;
		width: 12.5px;
		height: 12.5px;
		border-style: solid;
		border-color: #919191;
		border-width: 0 1px 1px 0;
		@include transform(rotate(45deg));
		transform-origin: 0 0;

		i {
			display: none;
		}
	}
	
	/** options **/
	.selectboxit-options {
		margin-top: 0;
		padding: 24.5px 0;
		
		a {
			height: auto;
			padding: 7.5px 22px;
		}
	}
	
	.selectboxit-option {
		text-indent: 0;
		
		&.selectboxit-selected a,
  		&.selectboxit-focus a, 
  		&:hover a {
			color: #f4436c;  
		}
	}
}

.text-link {
	position: relative;
	display: inline-block;
	border: 0;
	border-bottom: 1px solid;
	padding: 0 0 7px 0;
	background: none;
	text-decoration: none;
	margin-top: 20px;
	
	&._9e7957 {
		color: #9e7957!important;
		border-color: #9e7957!important;
	}
}

.announcement-banner {
	background-color: #efebe8;
	padding-top: 46.4px;
	padding-bottom: 49.6px;

  @media only screen and (max-width: $bs-md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
	
	h3 {
		padding-right: 69px;

    @media only screen and (max-width: $bs-xs) {
      padding-right: 0;
      margin-bottom: 15px;
    }
	}
	
	a {
		color: #f4436c;
		text-decoration: none;
    display: inline;

    span {
      box-shadow: inset 0 -1px #f4436c, inset 0 -1px #f4436c;
    }
	}
	
	&_text {
		max-width: 471px;
		padding-right: 51px;
    
    @media only screen and (max-width: $bs-xs) {
      padding-right: 0;
      margin-bottom: 15px;
      max-width: none;
    }
	}
}

header.template {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	padding-top: 80px;
	padding-bottom: 39px;
	@include transition(padding 0.5s ease, background 0.5s ease);
	
	@media only screen and (max-width: $bs-md) {
		padding-top: 21px;
		padding-bottom: 21px;
	}
	
	.navbar {
		padding: 0;
		
		@media only screen and (max-width: $bs-md) {
			position: fixed;
			top: 0;
			right: -100%;
			opacity: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			background-color: rgba(51, 51, 51, 0.8);

			-webkit-transition: opacity 0.5s ease;
			        transition: opacity 0.5s ease;

			.wrapper {
				position: absolute;
				top: 0;
				right: -100%;
				width: calc(100% - 60px);
				height: 100%;
				background-color: #ffffff;
				padding: 0 30px;
				overflow: auto;

				-webkit-transition: right 0.5s ease;
				        transition: right 0.5s ease;
			}
		}
	}
	
	.navbar-brand {
		padding: 0;

		a {
			display: inline-block;
			width: 118px;
			height: 46px;

			@media only screen and (max-width: $bs-md) {
				width: 76px;
				height: 33px;
			}
		}
	}
	
	.navbar-nav {
		margin-right: -9px;
		
		@media only screen and (max-width: $bs-md) {
			margin-bottom: 25px;
			padding-top: 33px;
		}
	}
	
	.nav-item {
		position: relative;
		margin-left: 9px;
		margin-right: 9px;
		
		@media only screen and (max-width: $bs-md) {
			margin-left: 0;
			margin-right: 0;
		}
		
		a {
			padding-top: 19px;
			padding-bottom: 11px;
			display: block;
			text-decoration: none;
			
			@media only screen and (max-width: $bs-md) {
				padding: 13px 0;
			}
			
			&:after {
				display: none;
			}
			
			@at-root header.template .nav-item.show > a,
			&:hover {
				color: #f4436c;
				background: none;
			}
			
			@at-root header.template .nav-item.show a,
			&.active, &:hover {
				&:after {
					content: "";
					width: 6px;
					height: 6px;
					display: block;
					background-color: #f4436c;
					border-radius: 50%;
					position: absolute;
					bottom: 0;
					left: 50%;
					margin-left: 0;
					border: 0;
					
					@media only screen and (max-width: $bs-md) {
						display: none;
					}
				}
			}
			
			@media only screen and (max-width: $bs-md) {
				&.dropdown-toggle {
					span {
						position: relative;
						padding-right: 7px + 12px;
						
						&:after {
							content: "";
							display: inline-block;
							width: 12px;
							height: 12px;
							border-style: solid;
							border-color: #000000;
							transform-origin: 0 0;
							position: absolute;
							right: -7px;
							@include transform(rotate(45deg));
						}
					}
					
					&[aria-expanded="false"] span:after {
						border-width: 0 2px 2px 0;
						top: 5px;
					}
					
					&[aria-expanded="true"] span:after {
						border-width: 2px 0 0 2px;
						top: 10px;
					}
				}
			}
		}
		
		.dropdown-menu {
			padding: 40px;
			top: calc(100% + 6px);
			left: 50%;
			@include transform(translate(-50%,0%));
			border: 0;
			
			@media only screen and (max-width: $bs-md) {
				transform: none;
				padding: 13px 0 15px 0;
			}
			
			a {
				padding: 0 0 15px 0;
				color: #000000;
				
				&:after {
					display: none!important;
				}
				
				&.active, &:hover {
					color: #f4436c;
				}
			}
			
			li:last-child a {
				padding-bottom: 0;
			}
		}
		
		&_special {
			margin-top: 10px;
		}
	}
	
	#menu_toggle {
		width: 25px;
		position: relative;
		
		span {
			display: block;
			width: 100%;
			height: 2px;
			background-color: #000000;
			margin-bottom: 2px;
		}
		
		span:last-of-type {
			margin-bottom: 0;
		}
	}
}

header.template {
	background-color: rgba(255,255,255,0.85);

	.nav-item a {
		color: #000000;
	}
}

@media only screen and (max-width: $bs-md) {
	body.nav_open {
		header.template {
			#menu_toggle {
				position: fixed;
				top: 42px;
				z-index: 3;
				width: 25px;
				height: 25px;
				
				span {
					background-color: #ffffff;
					position: absolute;
					width: 30px;
					top: 5px;
					
					&:first-of-type {
						-webkit-transform: rotate(45deg);
						        transform: rotate(45deg);
					}
					
					&:last-of-type {
						-webkit-transform: rotate(-45deg);
						        transform: rotate(-45deg);
					}
					
					&:nth-child(2) {
						display: none;
					}
				}
			}
			
			.navbar {
				right: 0;
				opacity: 1;
			}
			
			.wrapper {
				right: 0;
			}
		}
	}
}

body.sticky_header {
	header.template {
		padding-top: 20px;
	}
}

.nav-item_special {
	color: #FFFFFF!important;
	background-color: #f4436c!important;
	border-radius: 19.5px!important;
	padding: 7px 24px 11px 24px !important;
	display: inline-block!important;
	border: 1px solid #f4436c!important;
	text-decoration: none!important;
	
	@media only screen and (max-width: $bs-sm) {
		margin-top: 20px;
		margin-top: 25px;
	}
}

.nav-item_special:hover {
	background: none!important;
	color: #f4436c!important;
}

.nav-item_special:hover:after {
	display: none!important;
}

main.template {
	margin-top: 170px;
	padding-bottom: 148px;
	position: relative;
	background-color: #ffffff;
	
	@media only screen and (max-width: $bs-md) {
		padding-bottom: 50px;
		margin-top: 72px;
	}
}

#banner {
	width: 100%;
	min-height: 48px;
	position: relative;
	
	picture {
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
}

h1.page-title {
	max-width: 657px;
	width: 100%;
	margin-bottom: 66px;

  @media only screen and (max-width: $bs-md) {
    margin-bottom: 25px;
  }
}

.section__two-col-text {
	padding-top: 48px;
	padding-bottom: 100px;

  @media only screen and (max-width: $bs-md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
	
	&-item {
		h2.section__title {
			margin-bottom: 46px;
			width: 50%;
			
			@media only screen and (max-width: $bs-md) {
				width: 100%;
        margin-bottom: 28px;
			}
		}
		
		&_content {
			column-count: 2;
			
			@media only screen and (max-width: $bs-sm) {
				column-count: 1;
			}
		}

		&._indented-column {
			padding-left: 162px;
			
			@media only screen and (max-width: $bs-md) {
				padding-left: 0;
			}
			
			h2.section__title {
				margin-left: -81px;
				
				@media only screen and (max-width: $bs-md) {
					margin-left: 0;
				}
			}
		}
	}
}

.section__img_bkgd_txt {
	picture {
		display: block;
		width: 100%;
		max-height: 640px;
		overflow: hidden;
		
		span {
			display: block;
			width: 100%;
			padding-bottom: 56.25%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}
	}
	
	.section__img_bkgd_txt_content {
		position: absolute;
		bottom: 0;
		right: 0;
		background-color: #FFFFFF;
		padding: 75px 68px;
		max-width: 569px;
		width: 100%;
		
		@media only screen and (max-width: $bs-md) {
			position: relative;
			bottom: auto;
			right: auto;
			max-width: none;
			padding: 29.6px 0 45px 0;
		}
	}
}

.section__txt_row {
	padding-top: 117px;
	padding-bottom: 96px;
	
	@media only screen and (max-width: $bs-md) {
		padding-top: 0;
    padding-bottom: 30px;
	}
  
	@media only screen and (max-width: $bs-sm) {
		padding-top: 0;
    padding-bottom: 0;
	}
	
	h2.section__title {
		margin-bottom: 45.4px;
    
    @media only screen and (max-width: $bs-sm) {
      margin-bottom: 28px;
    }
	}
  
	h3.section__title {
		margin-bottom: 36px;

    @media only screen and (max-width: $bs-sm) {
      margin-bottom: 20px;
    }
	}

	.section__txt_row_item {
		
		@media only screen and (max-width: $bs-sm) {
			margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
		}
		
		hr {
			display: block;
			width: 100%;
			height: 7px;
			background-color: #f4436c;
			margin-top: 0;
			margin-bottom: 23px;
      opacity: 1;

      @media only screen and (max-width: $bs-md) {
        margin-bottom: 20px;
      }
		}
		
		&.col {
			padding-left: 27px;
			padding-right: 27px;
		}
	}
	
	&._style-1 {
    padding: 90px 0 93px 0;
		background-color: #EFEBE8;

    @media only screen and (max-width: $bs-sm) {
      padding: 35px 0;
    }
		
		h2.section__title {
			margin-bottom: 42px;
		}
		
		.section__txt_row_item {
			hr {
				display: none;
			}
			
			&.col {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
		
		strong {
			color: #f4436c;
		}
	}
}

.section__img {
	position: relative;
	width: 100%;
	height: 464px;
	
	picture {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		position: absolute;
		top: 0;
		left:0;
		width: 100%;
		height: 100%;
	}
}

.blog-item {
	width: calc(50% - 15px);
	position: relative;
	margin-right: 29px;
	margin-bottom: 48px;
	
	&:nth-child(2n) {
		margin-right: 0;
	}
	
	@media only screen and (max-width: $bs-sm) {
		width: 100%;
		margin-right: 0;
    margin-bottom: 20px;
	}

  &__picture-placeholder,
	picture {
		display: block;
		width: 100%;
		padding-bottom: 56.25%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		//right: 0;
		left: 0;
		width: 100%;
    
		@media only screen and (max-width: $bs-sm) {
			position: relative;
			top: auto;
			right: auto;
		}
	}
  
  &__picture-placeholder {
    background-color: #cccccc;
  }

	&_content {
		overflow: hidden;
		width: 80%;
		margin-top: 128px;
		padding: 41px 30px;
		background-color: #ffffff;
    padding-bottom: 42.7px;
    position: relative;
		
		@media only screen and (max-width: $bs-sm) {
			width: 100%;
			margin-top: 0;
      padding: 20px;
		}
		
		h2 {
			margin-bottom: 15px;

      @media only screen and (max-width: $bs-sm) {
        margin-bottom: 10px;
      }
		}
    
    .text-link {
      position: absolute;
      bottom: 0;
      left: 30px;
      margin-top: 10px;

      @media only screen and (max-width: $bs-sm) {
        position: relative;
        bottom: auto;
        left: auto;
      }
    }
	}
}

footer.template {
	background-color: #ffffff;
	position: relative;
	
	.footer__wrapper {
		border-top: 1px solid #C9C9C9;
		padding: 42px 0 72px 0;
    
    @media only screen and (max-width: $bs-md) {
      padding: 25.8px 0 25.5px 0;
    }
	}
	
	.navbar-brand {
		min-width: 80px;
		height: auto;
		margin-right: 75px / $container * 100%;
		
		@media only screen and (max-width: $bs-sm) {
			margin-bottom: 20px;
      max-width: 80px;
      min-width: 0;
		}
	}
	
	address {
		max-width: 325px;
    
		@media only screen and (max-width: $bs-md) {
			margin-bottom: 25px;
		}
	}
	
	nav {
		margin-left: 6.96143%;

    &.footer__nav {
      margin-right: 5%;
    }
		
		@media only screen and (max-width: $bs-md) {
			margin-left: 5%;
		}
		
		@media only screen and (max-width: $bs-sm) {
			margin-left: 0;
      margin-right: 70px;

      &:last-child {
        margin-right: 0;
      }
		}

    ul {
      margin-bottom: 0;
    }
		
		.nav-item {
      line-height: 0;
      
			a {
				color: #000000;
				padding-bottom: 13px;
				display: inline-block;
				text-decoration: none;
			}
		}
	}
	
	.footer__nav__social-media {
		margin-left: -7px;
		
		a {
			color: #000000;
			display: inline-block;
			padding: 0 7px;
		}
	}

  .footer__nav__get-quote {
    text-align: left;

    @media only screen and (max-width: $bs-md) {
      text-align: center;
      margin-right: 0;
    }
  }
}
