body.home.page header.template {
	background: none;

	.navbar-brand {
		a {
			background-image: url(../img/logo-ffffff.svg);
			background-repeat: no-repeat;
			img { display: none; }
		}
	}

	.nav-item a {
		color: #ffffff;
	}

	.nav-item_special {
		color: #F4436C!important;
		background-color: #ffffff!important;
		border: 1px solid #ffffff!important;
	}

	#menu_toggle span {
		background-color: #ffffff;
	}
}

body.home.page.header-opaque header.template {
	background-color: rgba(255,255,255,0.85)!important;

	.navbar-brand {
		a {
			background-image: url(../img/logo-f4436c.svg);
		}
	}

	.nav-item a {
		color: #000000;
	}

	.nav-item_special {
		color: #FFFFFF!important;
		background-color: #f4436c!important;
		border: 1px solid #f4436c!important;
	}

	#menu_toggle span {
		background-color: #000000;
	}
}

body.home.page.nav_open header.template {
	.nav-item a {
		color: #000000;
	}

	.nav-item_special {
		color: #FFFFFF!important;
		background-color: #f4436c!important;
		border: 1px solid #f4436c!important;
	}
}

.home.page {
	main.template {
		padding-bottom: 0;
		margin-top: 0;
	}
	
	h2.section__title {
    text-transform: lowercase;
    
		a.text-link {
			margin-left: 24px;
		}
	}
	
	#banner {
		background-color: #f4436c;
		color: #ffffff;
		padding-top: 170px;

		@media only screen and (max-width: $bs-md) {
			padding-top: 72px;
		}

		.banner-item_text {
			padding: 110px 0 127px 0;

			@media only screen and (max-width: $bs-xs) {
				padding: 69px 0 70px 0;
			}
		}

		.text-slide-up {
			overflow: hidden;
			animation-delay: 0;

			& > span {
				display: block;
				width: max-content;
				white-space: nowrap;
				line-height: 117%;
				animation: slide-up 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;
			}

			&:nth-child(0) span { animation-delay: 0.025s; }
			&:nth-child(1) span { animation-delay: 0.05s; }
			&:nth-child(2) span { animation-delay: 0.075s; }
			&:nth-child(3) span { animation-delay: 0.1s; }
		}

		@keyframes slide-up {
			0% {
				transform: translateY(100px)
			}
			100% {
				transform: translateY(0)
			}
		}
	}
}

.home.page .call-to-action {
	background-color: #f4436c;
	color: #ffffff;
	text-align: center;
	padding: 16px 0;
	display: block;
	text-decoration: none;
}

.home.page .section__two-col-text {
	padding: 99px 0 93px 0;
	
	@media only screen and (max-width: $bs-sm) {
		padding: 46px 0;
	}
	
	.section__two-col-text-item {
		&_content {
			padding-left: px2vw(162px);
			
			@media only screen and (min-width: $container) {
				padding-left: 162px;
			}
			
			@media only screen and (max-width: $bs-xs) {
				padding-left: 40px;
			}
		}
	}
}

.home.page #section__client_listing {
	padding: 99px 0 146px 0;
	
	@media only screen and (max-width: $bs-sm) {
		padding: 50px 0 55.4px 0;
	}

	h2.section__title {
		margin-bottom: 58px;
    @media only screen and (max-width: $bs-sm) {
      margin-bottom: 20px;
    }
	}

	.section__client-item {
		max-width: 300px;
		width: 20%;
    height: 187px;
    // height: 290px;
		// padding: 25px 49px 28px 48px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
		
    /*
		picture {
			span {
				display: block;
				width: 100%;
				padding-bottom: 29%;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
			}
		}
    */
	}
}

.home.page #our-services {
	background-color: #000000;
	padding-top: 86px;
	padding-bottom: calc(144px - 21px);
	
	@media only screen and (max-width: $bs-sm) {
		padding-top: 43px;
		padding-bottom: 43px;
	}
	
	h2.section__title {
		margin-bottom: 73px;
		
		@media only screen and (max-width: $bs-xs) {
			margin-bottom: 17px;
		}
	}
	
	& * {
		color: #ffffff;
	}
	
	aside {
		padding-right: 45px;
		// width: 36.363636%;
		width: 465px / $container * 100%;
		position: relative;
		
		@media only screen and (max-width: $bs-sm) {
			width: 100%;
			padding-right: 0;
			margin-bottom: 30px;
		}

		.our-services-content {
			width: 100%;
			opacity: 0;
			-webkit-transition: opacity 0.5s ease;
					transition: opacity 0.5s ease;
					
			&.show {
				opacity: 1;
			}
		}

		h2 {
			margin-bottom: 21px;
      text-transform: lowercase;

      @media only screen and (max-width: $bs-xs) {
        margin-bottom: 10px;
      }
		}
	}
	
	main {
		width: 100% - (465px / $container * 100%);

		@media only screen and (max-width: $bs-sm) {
			width: 100%;
		}
	}
	
	.our-services_item {
		width: calc(50% - 18px);
		border: 0;
		border-bottom: 1px solid #6d6d6d;
		padding-bottom: 7px;
		margin-bottom: 21px;
		margin-right: 36px;
		background: none;
		display: block;
		
		&:nth-child(even) {
			margin-right: 0;
		}
		
		@media only screen and (max-width: $bs-xs) {
			width: 100%;
			margin-right: 0;
		}
		
		label {
			color: #999999;
			margin: 0;
			cursor: pointer;
		}
		
		& * {
			-webkit-transition: color 0.5s ease;
					transition: color 0.5s ease;
		}
		
		&.active *,
		&:hover * {
			color: #9e7957;
		}
	}
}

.home.page #latest-blog {
	padding-top: 146px;
	padding-bottom: 282px;
	
	@media only screen and (max-width: $bs-sm) {
		padding-top: 73px;
		padding-bottom: 50px;
	}
}

.home.page .blog-item {
	width: 100%;
	margin-bottom: 0;
	
	picture {
		width: 50%;
		padding-bottom: 0;
		height: 400px;

		@media only screen and (max-width: $bs-md) {
			width: 100%;
      margin-bottom: -60px;
		}
	}
	
	&_content {
		width: 67%;
    padding: 66px 61px 40px 61px;
		min-height: 272px;
    
    @media only screen and (max-width: $bs-sm) {
      width: 80%;
    }
    
    @media only screen and (max-width: $bs-xs) {
      width: 95%;
      padding: 29.6px 30px;
    }

		h2 {
			margin-bottom: 32px;
		}

    .text-link {
      left: 61px;

      @media only screen and (max-width: $bs-sm) {
        left: 0;
      }
    }
	}
}

.home.page #promotion {
	background-color: #efebe8;
	padding-top: 82px;
	padding-bottom: 86px;
	
	@media only screen and (max-width: $bs-sm) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	
	h3.section__title {
		color: #f4436c;
		margin-bottom: 24px;

    @media only screen and (max-width: $bs-xs) {
      margin-bottom: 15px;
    }
	}
	
	#section__testimonials {
		@media only screen and (max-width: $bs-sm) {
			margin-top: 40px;
		}
	}
	
	.testimonial-item_name {
		position: relative;
		padding-left: 31px;
		margin-top: 20px;
		
		&:before {
			content: "";
			position: absolute;
			top: 13px;
			left: 0;
			width: 20px;
			height: 2px;
			background-color: #000000;
		}
	}
	
	.owl-dots {
		line-height: 0;
		margin-top: 39px;
	}
	
	.owl-dot {
		display: inline-block;
		width: 6px;
		height: 6px;
		border: 1px solid #494949;
		border-radius: 50%;
		margin-right: 7px;
		
		&:last-of-type {
			margin-right: 0;
		}

		&.active {
			background-color: #494949;
		}
	}
}

.home.page #latest-projects {
	padding-top: 136px;
	padding-bottom: 139px;
	
	@media only screen and (max-width: $bs-lg) {
		padding-top: 70px;
		padding-bottom: 60px;
	}

	.latest-project-item {
		margin-bottom: 114px;
		overflow: hidden;
		
		&:last-child {
			margin-bottom: 0;
		}

		@media only screen and (max-width: $bs-sm) {
			margin-bottom: 40px;
			width: 100%!important;
		}
		
		& > a {
			display: block;
		}
		
		picture {
			display: block;
			width: 100%;
			margin-bottom: 18px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
		}
		
		&:nth-child(1) {
			width: 430px;
			
			picture {
				height: 430px;
			}
		}
		
		&:nth-child(2) {
			width: 600px;
			
			picture {
				height: 600px;
			}
		}
		
		&:nth-child(3) {
			width: 100%;
			
			picture {
				height: 450px;
			}
		}
		
		label {
			color: #000000;
			margin: 0;
		}
	}
}

.project-listing {

	&_filter {
		@media only screen and (max-width: $bs-sm) {
			margin-bottom: 50px;
			
			.selectboxit-container {
				margin-bottom: 20px;
			}
		}

		.selectboxit-option-icon {
			display: none;
		}
	}
	
	&-item {
		position: relative;
		width: 25%;
		display: block;
		
		@media only screen and (max-width: $bs-md) {
			width: 50%;
		}
		
		@media only screen and (max-width: $bs-xs) {
			width: 100%;
		}
		
		picture {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			display: block;
			width: 100%;
			padding-bottom: 100%;
		}
		
		&_overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255,255,255,0.9);
			opacity: 0;
			@include transition(opacity 0.5s ease);
		}
		
		&:hover &_overlay {
			opacity: 1;
		}
		
		&_text {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			@include transform(translate(0,-50%));
			padding: 0 30px;

			* {
				color: #000000;
			}

			h2 {
				color: #f4436c;
				margin-bottom: 26px;
			}
			
			p {
				margin-bottom: 0;
			}
		}
	}
}

.project-listing.page {
	h1.page-title {
		margin-bottom: 46px;
	}
	
	.project-listing_text {
		margin-top: 92px;

    @media only screen and (max-width: $bs-sm) {
      margin-top: 47px;
    }
		
		&-wrapper {
			max-width: 695px;
			width: 100%;
		}
		
		a.special-link {
			color: #f4436c;
			border-bottom: 1px solid #f4436c;
			padding-bottom: 11px;
			margin-top: 38px;
		}
	}
}

.project.page {
	#banner {
		height: calc(105vh - 241px);
		z-index: 0;
		position: fixed;

		picture {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}
	}
	
	#banner-cover {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 20%;
		background-color: #ffffff;
	}
	
	.project-detail {
		// position: absolute;
		// bottom: 0;
		// width: 100%;
		margin-top: 60vh;
		position: relative;
		z-index: 1;
		
		.jump-to-section {
			background: none;
			border: 0;
			position: relative;
			background-image: url(../img/long-arrow_down.svg);
			background-size: 15px 60px;
			background-repeat: no-repeat;
			background-position: center center;
			width: 15px;
			height: 60px;

			span {
				display: none;
			}
		}

		&_info {
			padding: 55px 53px;
			background-color: #FFFFFF;
      
      @media only screen and (max-width: $bs-md) {
        padding: 20px;
      }
		}
		
		& * {
			color: #000000;
		}
		
		h1.page-title {
			color: #f4436c;
			margin-bottom: 26px;
      
      @media only screen and (max-width: $bs-md) {
        margin-bottom: 10px;
      }
		}

		main {
			width: 100%;
			order: 2;
		}
		
		aside {
			width: 33.746425%;
			order: 1;
		}
		
		aside + main {
			max-width: 695px;
			width: 66.253575%;
		}
		
		&_type {
			margin-bottom: 50px;
      
      @media only screen and (max-width: $bs-md) {
        margin-bottom: 20px;
      }
		}
		
		&_website-link {
			border-bottom: 1px solid #000000;
			padding-bottom: 4px;
		}
	}

  .project-image-listing {
    background-color: #ffffff;
  }
	
	.project-description {
		background-color: #EFEBE8;
		padding: 79px 0 70px 0;
    
    @media only screen and (max-width: $bs-md) {
      padding: 35px 0;
    }
	}
	
	.project-gallery {
		&-item {
			position: relative;
			width: 100%;
			// padding-bottom: 56.25%;
			height: 576px;

			@media only screen and (max-width: $bs-md) {
				height: 288px;
			}
			
			picture {
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		
		.owl-nav {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 1;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			
			button {
				background-color: rgba(255,255,255,0.9);
				width: 49px;
				height: 49px;
				position: relative;
				margin-left: 2px;

				span {
					display: none;
				}

				&:after {
					content: "";
					border-style: solid;
					border-color: #000000;
					@include transform(rotate(-45deg) translate(-50%,-50%));
					transform-origin: 0 0;
					width: 10px;
					height: 10px;
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
				}
				
				&.owl-prev:after {
					border-width: 1px 0 0 1px;
				}
				
				&.owl-next:after {
					border-width: 0 1px 1px 0;
				}
			}
		}
	}
}

.section__blog_loadmore {
	background: none;
	border: 0;
	text-align: center;
	position: relative;
	display: block;
	width: 100%;
	
	&:after {
		content: "";
		position: absolute;
		top: 55%;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: #9E7957;
	}
	
	span {
		display: inline-block;
		padding-left: 21px;
		padding-right: 21px;
		color: #9E7957;
		background-color: #ffffff;
		position: relative;
		z-index: 1;
	}
}

.blog-story {
	&_back {
		color: #9E7957;
		border-bottom: 1px solid #9E7957;
		padding-bottom: 4px;
		text-decoration: none;
		
		&:focus,
		&:hover {
			color: #9E7957;
		}

    @media only screen and (max-width: $bs-sm) {
      display: none;
    }
	}
	
	picture {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		width: 50%;
		
		@media only screen and (max-width: $bs-sm) {
			position: relative;
			top: auto;
			left: auto;
			width: 100%;
		}
	}
	
	&_content {
		width: 60%;
		margin-left: -126px;
		padding: 52px 49px;
		margin-top: 67px;
		position: relative;
		z-index: 1;
		background-color: #ffffff;

    @media only screen and (max-width: $bs-md) {
      padding: 20px;
    }
		
		@media only screen and (max-width: $bs-sm) {
			width: 100%;
			margin-left: 0;
			margin-top: 0;
		}
		
		@media only screen and (max-width: $bs-xs) {
			padding: 26px 0;
		}
	}
}

.contact.page {
	.header {
		position: relative;
		width: 100%;
		height: 530px;
		
		@media only screen and (max-width: $bs-md) {
			height: auto;
		}
	}
	
	#map_canvas {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		
		@media only screen and (max-width: $bs-md) {
			height: 400px;
			position: relative;
			top: auto;
			left: auto;
		}
	}
	
	.header__content {
		position: absolute;
		bottom: 0;
		width: 100%;
		
		@media only screen and (max-width: $bs-md) {
			position: relative;
			bottom: auto;
		}
		
		&:before {
			content: "";
			width: 50%;
			height: 100%;
			background-color: #ffffff;
			position: absolute;
			top: 0;
			left: 0;
      z-index: -1;
		}
		
		h1.page-title {
			width: auto;
			max-width: none;
      padding-top: 64px;
      margin-bottom: 34px;

      @media only screen and (max-width: $bs-md) {
        padding-top: 30px;
        margin-bottom: 21px;
      }
		}

    address {
      padding-top: 64px;
      margin-bottom: 34px;

      @media only screen and (max-width: $bs-md) {
        padding-top: 0;
        margin-bottom: 20px;
      }
    }
	}

	.section__contact {
		margin-bottom: 56px;

    @media only screen and (max-width: $bs-md) {
      margin-bottom: 64px;
    }
	}

	.section__contact {
		dt, dd {
			display: inline-block;
			margin-bottom: 0;
		}
		
		dt {
			margin-right: 6px;
		}
	}

	form {
		width: 200%;

		@media only screen and (max-width: $bs-md) {
			width: 100%;
		}

		.form-group {
			margin-bottom: 43px;
      
      @media only screen and (max-width: $bs-md) {
        margin-bottom: 10px;
      }
		}
		
		label {
			margin-bottom: 11px;
		}
		
		input, textarea {
			border: 0;
			background: none;
			border-bottom: 1px solid #C9C9C9;
			width: 100%;

			&.error {
				border-color: #ff0000;
			}
		}
		
		input {
			height: 35px;
		}
	}
}

.section__client-listing {
	.container {
		max-width: 779px;
	}
	
	h1.page-title {
		margin-bottom: 40px;
	}
	
	a {
		text-decoration: none;
	}
}
