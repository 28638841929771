/*
 * reset.css
 * START
 *
 */

/*
 * disable input field selection outline
 */
html {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
	overflow-x: hidden;
}

p:empty {
	display: none;
}

textarea:focus, input:focus,
*:focus, a:focus {
    outline: 0;
}

a:focus, a:hover {
    color: inherit;
    text-decoration: none;
}

input,
textarea {
    /*-webkit-appearance: none;*/
    border-radius: 0;
}

textarea {
	resize: none;
}

button:focus:not(:focus-visible), button:focus {
	outline: 0;
	box-shadow: none;
}

figure {
	margin: 0;
}

svg {
	margin: auto;
}

/*
 * reset.css
 * END
 */