/*
 * jquery.selectBoxIt.css 3.8.1
 * Author: @gregfranko
 */

/*
  Common CSS Properties
  ---------------------
  These properties will be applied to any themes that you use
*/

/* SelectBoxIt container */
.selectboxit-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

/* Styles that apply to all SelectBoxIt elements */
.selectboxit-container * {
  /*font: 14px Helvetica, Arial;*/
  /* Prevents text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  white-space: nowrap;
}

/* Button */
.selectboxit-container .selectboxit {
  width: 220px; /* Width of the dropdown button */
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  display: block;
  position: relative;
}

/* Height and Vertical Alignment of Text */
.selectboxit-container .selectboxit-text, .selectboxit-container .selectboxit-options a {
  height: 40px; /* Height of the drop down */
  line-height: 40px; /* Vertically positions the drop down text */
  display: block;
}

/* Focus pseudo selector */
.selectboxit-container .selectboxit:focus {
  outline: 0;
}

/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled, .selectboxit-options .selectboxit-disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
}

/* Button Text */
.selectboxit-text {
  /*text-indent: 5px;*/
  overflow: hidden;
  text-overflow: ellipsis;
  /*float: left;*/
}

.selectboxit .selectboxit-option-icon-container {
  margin-left: 5px;
}

/* Options List */
.selectboxit-container .selectboxit-options {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 100%;  /* Minimum Width of the dropdown list box options */
  *width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  display: none;
  z-index: 9999999999999;
  border-radius: 6px;
  text-align: left;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-top: 20px;
}

/* Individual options */
/*
.selectboxit-option .selectboxit-option-anchor{
    padding: 0 2px;
}
*/

/* Individual Option Hover Action */
.selectboxit-option .selectboxit-option-anchor:hover {
  text-decoration: none;
}

/* Individual Option Optgroup Header */
.selectboxit-option, .selectboxit-optgroup-header {
  text-indent: 5px; /* Horizontal Positioning of the select box option text */
  margin: 0;
  list-style-type: none;
}

/* The first Drop Down option */
.selectboxit-option-first {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

/* The first Drop Down option optgroup */
.selectboxit-optgroup-header + .selectboxit-option-first {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

/* The last Drop Down option */
.selectboxit-option-last {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

/* Drop Down optgroup headers */
.selectboxit-optgroup-header {
  font-weight: bold;
}

/* Drop Down optgroup header hover psuedo class */
.selectboxit-optgroup-header:hover {
  cursor: default;
}

/* Drop Down down arrow container */
.selectboxit-arrow-container {
  /* Positions the down arrow */
  width: 30px;
  position: absolute;
  right: 0;
}

/* Drop Down down arrow */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  /* Horizontally centers the down arrow */
  margin: 0 auto;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
}

/* Drop Down down arrow for jQueryUI and jQuery Mobile */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow.ui-icon {
  top: 30%;
}

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-container {
  float: left;
}

.selectboxit-container .selectboxit-option-icon {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-url {
  width: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
  float: left;
}

.selectboxit-rendering {
  display: inline-block !important;
  *display: inline !important;
  zoom: 1 !important;
  visibility: visible !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

/* jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon {
  background-color: inherit;
}

/* Another jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon-triangle-1-s {
  background-position: -64px -16px;
}

/*
  Default Theme
  -------------
  Note: Feel free to remove all of the CSS underneath this line if you are not using the default theme
*/

/*
.selectboxit-container {
    width: 100%;
}
*/

/*
.selectboxit-container * {
    font-family: 'Fira Sans', sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
    color: #333333;
    text-transform: capitalize;
}
*/

.selectboxit-container .selectboxit {
    background: none;
    border-bottom: 1px solid rgba(160, 160, 160, 0.6);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    /*max-width: 252px;*/
/*    width: 100%;*/
/*    height: 40px;*/
    border-radius: 0;
}

.selectboxit .selectboxit-option-icon-container {
    margin-left: 0;
    display: none;
}

/*.selectboxit-container > .selectboxit > .selectboxit-text {
    height: 40px;
    line-height: 40px!important;
    font-size: 15px;
    font-size: 1.5rem;
}*/

/*.selectboxit-container .selectboxit-arrow-container {
    background-image: url(images/arrow_ffffff.png);
    background-repeat: no-repeat;
    background-size: 16px 9px;
    background-position: center;
    width: 16px;
    height: 40px;
}*/

/*
.selectboxit-container .selectboxit-arrow-container > i {
    display: none;
}
*/

.selectboxit-btn {
    background-color: #f5f5f5;
    background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
    background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
    background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    border: 1px solid #cccccc;
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border-bottom-color: #b3b3b3;
}

.selectboxit-default-arrow {
    width: 0;
    height: 0;
    border-top: 4px solid #000000;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.selectboxit-list {
    background-color: #ffffff;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.selectboxit-list .selectboxit-option-anchor {
    color: #333333;
    /*border-bottom: 1px solid rgba(80, 83, 83, 0.3);*/
    /*max-width: 252px;*/
}

/* Options List */
.selectboxit-container .selectboxit-options {
	border-radius: 0;
}

/* The first Drop Down option */
.selectboxit-option-first {
    border-radius: 0;
}

/* The first Drop Down option optgroup */
.selectboxit-optgroup-header + .selectboxit-option-first {
    border-radius: 0;
}

/* The last Drop Down option */
.selectboxit-option-last {
	border-radius: 0;
}

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
    /*background-color: #E4E4E4;*/
    color: #A0A0A0;
}
