/*
 * sticky_footer.css
 * START
 *
 */

body {
    /*display: flex; this creating conflict with jQuery slick*/
    margin: 0;
}

.wrapper.template {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
}

main.template {
    flex: 1 1 auto;
    /*width: 100vw;*/
    width: 100%;
    /*overflow: hidden;*/
}

/*
 * sticky_footer.css
 * END
 */